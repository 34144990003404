body {
  padding: 12px 12px 12px 12px;
  font-size: 20px;
  background-color: #fdfdfd;
}
#viewport {
  display: block;
  position: relative;
  border: 1px solid #ccc;
  width: 50%;
  margin: 0 auto;
}
#fps-container {
  position: absolute;
  top: 620px;
}
.target {
  position: absolute;
  background-image: url("lettuce.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
}
.bunker {
  position: absolute;
  background-image: url("crate.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.bomb {
  background: rgb(63, 56, 31);
  position: absolute;
  border-radius: 6px;
}
.ufo {
  position: absolute;
  background-image: url("cloud.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-y: center;
}
#floor {
  background-color: rgba(109, 102, 93, 0.952);
  position: absolute;
}
#ceiling {
  background-color: rgb(34, 146, 184);
  position: absolute;
}
.turret {
  /* background: rgb(34, 146, 184);
  display: inline-block;
  clip-path: polygon(50% 0%, 0% 100%, 100% 100%); */
  position: relative;
  background-image: url("bunna.png");
  background-repeat: no-repeat;
  background-size: contain;
}
.bullet {
  position: absolute;
  background-image: url("carrot.png");
  background-repeat: no-repeat;
  background-size: contain;
}

#left-pane {
  position: absolute;
}
#score,
.score-label,
.lives-label {
  font-family: "Orbitron", sans-serif;
  font-size: 28px;
}
.life {
  background-image: url("bunna.png");
  background-repeat: no-repeat;
  background-size: contain;
}
#title {
  font-family: "Orbitron", sans-serif;
  font-size: 34px;
}
